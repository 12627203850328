import TrackVisibilityWrapper from 'components/TrackVisibilityWrapper';
import React from 'react';
import { CTA, IGatsbyImage } from 'utils/types';

import { HowItWorksSection } from '../../components/HowItWorksSection';

export interface HowItWorksContainerProps {
  content?: {
    title?: string;
    headline?: string;
    // To avoid any confusion - this disclaimerText prop is actually coming from the modalButtonText in Contentful
    disclaimerText?: string;
    listOfContentSections?: {
      headline: string;
      subHeadline: string;
      image: IGatsbyImage;
    }[];
    noNumberTags?: boolean;
    sectionId: string;
    isImageOnTheSide?: boolean;
    // To avoid changing Contentful due to the re-architecture, this will use the Link content model from Contentful.
    cta?: CTA;
  };
  onCtaClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export const HowItWorksContainer: React.FC<HowItWorksContainerProps> = ({
  content,
  onCtaClick,
}) => {
  if (!content) return null;

  const {
    title,
    headline,
    listOfContentSections,
    noNumberTags,
    sectionId,
    isImageOnTheSide,
    disclaimerText,
    cta,
  } = content;

  const props = {
    title,
    headline,
    disclaimerText,
    listOfContent: listOfContentSections?.map((section) => ({
      title: section.headline,
      description: section.subHeadline,
      image: section.image.gatsbyImageData,
      imageAlt: section.image.description || '',
    })),
    noNumberTags,
    sectionId,
    isImageOnTheSide,
    cta,
    onCtaClick,
  };

  return (
    <TrackVisibilityWrapper
      label={sectionId}
      slug={title || 'No title in Contentful'}
    >
      <HowItWorksSection {...props} />
    </TrackVisibilityWrapper>
  );
};

export default HowItWorksContainer;
